 <template>
  <v-container fluid class="pa-0 light_grey_background px-8">
    <headerToolbar color="white" divider>
      <template v-slot:toolbarTitle>
        <b class="ml-3">{{ $t("meetings.recurringMeeting") }}</b>
      </template>
    </headerToolbar>
    <v-container
      fluid
      class="pa-0 light_grey_background mx-auto mt-16"
      :class="{
        recurring_list: selectedTab == 'tab-list',
        recurring_calendar: selectedTab == 'tab-calendar',
      }"
    >
      <v-toolbar color="button_blue darken-3" height="72" dark flat>
        <v-list
          subheader
          two-line
          class="py-0"
          color="transparent"
          style="width: 100%"
        >
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title class="title">Team Tech </v-list-item-title>

              <v-list-item-subtitle
                >Tout les lundi à 14:50
                <v-chip x-small dark color="button_blue darken-1" class="ml-4">
                  <v-icon size="12" class="pr-2">mdi-timer</v-icon>
                  50min
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Page title</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn> -->

        <template v-slot:extension>
          <v-tabs v-model="selectedTab" centered slider-color="primary">
            <v-tab href="#tab-list"> List </v-tab>

            <v-tab href="#tab-calendar"> Calendar </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="tab-list">
          <v-card flat class="pa-4">
            <v-row no-gutters align="center" justify="center" wrap class="py-4">
              <v-col cols="4" align="end">
                <v-btn @click="goToPreviousMonth()" small outlined>
                  <v-icon> mdi-menu-left</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="4" align="center">
                <span class="title">
                  {{
                    $t("calendar.month_" + selected_month) + " " + selected_year
                  }}</span
                >
              </v-col>

              <v-col cols="4" align="start">
                <v-btn @click="goToNextMonth()" small outlined>
                  <v-icon> mdi-menu-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-list>
              <v-list-item v-for="i in 4" @click="" :class="{
                'purple_alert': i== 3
              }">

                <v-list-item-content>
                  <v-list-item-title class="body-2 font-weight-medium">
                    Lundi {{ 3 + (i - 1) * 7 }} Novembre

                    <v-chip
                      class="ml-4"
                      v-if="i == 3"
                      small
                      dark
                      color="amber darken-1"
                    >
                      Next meeting
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon size="14" class="pr-1">mdi-clock-outline</v-icon>
                    <span class="body-4 font-weight-bold">14:50</span>
                    <v-chip
                      x-small
                      class="grey--text text--darken-2 ml-4"
                      color="light_grey"
                    >
                      <v-icon size="12" class="pr-2">mdi-timer</v-icon>
                      50min
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-calendar">
          <v-card flat>
            <v-card-text>
              <!-- <FullCalendar :options="calendarOptions" /> -->
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
import headerToolbar from "../shared/headerToolbar";
import date from "@/mixins/date";
import { mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  name: "recurring_meeting_show",
  mixins: [date],
  components: {
    headerToolbar,
    FullCalendar,
  },
  data() {
    return {
      selected_month: new Date().getMonth(),
      selected_year: new Date().getFullYear(),
      selectedTab: null,
      Recurrence: {
        Pattern: {
          Month: 0,
          DayOfMonth: 0,
          FirstDayOfWeek: "Sunday",
          Index: "First",
          Type: "Daily",
        },
        Range: {
          NumberOfOccurrences: 0,
          Type: "EndDate",
          EndDate: "2016-09-09",
          StartDate: "2016-09-05",
          RecurrenceTimeZone: "Eastern Standard Time",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["selectedOrganisationId", "currentUser"]),
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        selectable: true,
        height: "100%",
        events: [],
        dateClick: this.handleDateClick,
      };
    },
  },
  methods: {
    goToPreviousMonth() {
      this.selected_month -= 1;
      if (this.selected_month < 0) {
        this.selected_month = 11;
        this.selected_year -= 1;
      }
    },
    goToNextMonth() {
      this.selected_month += 1;
      if (this.selected_month > 11) {
        this.selected_month = 0;
        this.selected_year += 1;
      }
    },
  },
  mounted() {
    document.title = this.$t("navigationMenu.upcomingMeeting");
  },
  watch: {},
  beforeDestroy() {},
};
</script>

<style scoped>
.recurring_list {
  max-width: 800px;
}

.recurring_calendar {
  max-width: 1280px;
}
</style>

<style>
</style>

